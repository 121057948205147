import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip, } from '@mui/material';
import { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocales } from '../../locales';
import Iconify from '../iconify';
export function RHFSelect({ name, label, native, maxHeight = 220, helperText, children, multiple, ...other }) {
    const { control, register } = useFormContext();
    const { translate } = useLocales();
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(Stack, { sx: `${translate(helperText)}` || `${translate(label)}`
                ? {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: '10px',
                    width: '100%',
                }
                : {}, children: [_jsx(TextField, { ...field, select: true, fullWidth: true, label: `${translate(label)}`, inputRef: field.ref, ...register(name), SelectProps: {
                        native,
                        MenuProps: {
                            PaperProps: {
                                sx: {
                                    ...(!native && {
                                        px: 1,
                                        maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                                        '& .MuiMenuItem-root': {
                                            px: 1,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        },
                                    }),
                                },
                            },
                        },
                        sx: { textTransform: 'capitalize' },
                    }, error: !!error, helperText: error ? `${translate(error?.message)}` : `${translate(helperText)}`, ...other, children: children }), (`${translate(helperText)}` || `${translate(label)}`) && (_jsx(Tooltip, { title: `${translate(helperText)}` || `${translate(label)}` || field.value, placement: "right-start", children: _jsx(Iconify, { icon: "ri:information-line", width: 23, sx: { cursor: 'help', color: '#9AA6B2', marginTop: error ? '-24px' : '' } }) }))] })) }));
}
export function RHFMultiSelect({ name, chip, label, options, checkbox, editSelected, placeholder, helperText, sx, ...other }) {
    const { control, watch } = useFormContext();
    const [allselectedItems, setSelectedItems] = useState(editSelected || []);
    const { translate } = useLocales();
    const renderValues = (value) => {
        const selectedIds = value;
        const selectedItems = options?.filter((item) => selectedIds.includes(item.id));
        if (!selectedItems.length && placeholder) {
            return (_jsx(Box, { component: "em", sx: { color: 'text.disabled' }, children: `${translate(placeholder)}` }));
        }
        if (chip) {
            return (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: selectedItems.map((item) => (_jsx(Chip, { size: "small", label: `${translate(item.label)} ` }, item.id))) }));
        }
        return selectedItems.map((item) => item.label).join(', ');
    };
    return (_jsx(Controller, { name: name, control: control, render: ({ field, fieldState: { error } }) => (_jsxs(FormControl, { sx: sx, children: [label && _jsx(InputLabel, { id: name, children: `${translate(label)}` }), _jsxs(Select, { ...field, multiple: true, displayEmpty: !!placeholder, labelId: name, input: _jsx(OutlinedInput, { fullWidth: true, label: `${translate(label)} `, error: !!error }), renderValue: renderValues, native: false, MenuProps: {
                        PaperProps: {
                            sx: { px: 1, maxHeight: 280 },
                        },
                    }, value: allselectedItems, ...other, children: [placeholder && (_jsx(MenuItem, { disabled: true, value: "", sx: {
                                py: 1,
                                px: 2,
                                borderRadius: 0.75,
                                typography: 'body2',
                            }, children: _jsx("em", { children: `${translate(placeholder)}` }) })), options.map((option) => {
                            const selected = allselectedItems?.includes(option?.id);
                            return (_jsxs(MenuItem, { value: option?.id, sx: {
                                    py: 1,
                                    px: 2,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    ...(selected && {
                                        fontWeight: 'fontWeightMedium',
                                    }),
                                    ...(checkbox && {
                                        p: 0.25,
                                    }),
                                }, onClick: () => setSelectedItems((prev) => prev.includes(option?.id)
                                    ? prev.filter((el) => el !== option?.id)
                                    : [...prev, option.id]), children: [checkbox && _jsx(Checkbox, { disableRipple: true, size: "small", checked: selected }), option.label] }, option?.id));
                        })] }), (!!error || helperText) && (_jsx(FormHelperText, { error: !!error, children: error ? `${translate(error?.message)}` : `${translate(helperText)}` }))] })) }));
}
